<template>
    <div class="PurchaseOrderCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-row>
                <el-col>
                    <el-radio v-model="purchaseSelfOutType" :label="0" @change="changePurchaseSelfOutType"
                        >根据单方采购入库单退货</el-radio
                    >
                    <el-radio v-model="purchaseSelfOutType" :label="1" @change="changePurchaseSelfOutType"
                        >自行选择商品退货</el-radio
                    >
                </el-col>
            </el-row>
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            ref="createForm"
            :model="form"
            :rules="rules"
            label-width="95px"
            size="small"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <template v-if="enableOrderRefundFlag">
                        <el-col :span="8">
                            <el-form-item label="单采入库单" prop="purchaseSelfInCode">
                                <el-input
                                    v-model="form.purchaseSelfInCode"
                                    @change="validateAndSelectPurchaseSelfInOrder"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="退货机构" prop="deptCode">
                                <el-select v-model="form.deptCode" filterable>
                                    <el-option
                                        v-for="item in depts"
                                        :label="item.name"
                                        :value="item.code"
                                        :key="item.code"
                                        :disabled="!item.selected"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="供应商">
                                <el-input readonly v-model="formExtend.supplierName" />
                            </el-form-item>
                        </el-col>
                    </template>
                    <template v-else>
                        <ef-dept-type-and-dept-select
                            @selectDeptType="setDeptType"
                            @selectDept="setDeptAndQueryHasBigUnit"
                        />
                        <el-col :span="8">
                            <el-form-item label="查询条件">
                                <el-input placeholder="" v-model="search" />
                            </el-form-item>
                        </el-col>
                    </template>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="客户" prop="customer">
                            <el-input v-model="form.customer" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户电话" prop="customerMobile">
                            <el-input v-model="form.customerMobile" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户地址" prop="customerAddress">
                            <el-input v-model="form.customerAddress" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="this.$store.state.session.name" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input readonly :value="formExtend.createTime" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <template v-if="enableOrderRefundFlag">
                    <el-button type="primary" @click="validateAndSelectPurchaseSelfInOrder" size="small"
                        >查询</el-button
                    >
                </template>
                <template v-else>
                    <el-button type="primary" @click="queryAndAdd" size="small" :disabled="disableQueryAndAddButton"
                        >查询并添加</el-button
                    >
                    <el-button
                        type="primary"
                        @click="showPickUpGoods"
                        size="small"
                        :disabled="disableShowPickUpGoodsDialog"
                        >选择商品基本信息</el-button
                    >
                </template>
                <el-button type="primary" @click="handleSave" size="small" :disabled="disableSaveButton"
                    >保存</el-button
                >
            </el-card>
            <pickUpGoods ref="pickUpGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="450"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品名称" width="180" fixed="left">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="130">
                        <template slot-scope="scope">
                            <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品类型" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).type | goodsType }}
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <template v-if="enableOrderRefundFlag">
                        <el-table-column label="采购数量" width="80" prop="purchaseCounts" key="order1" />
                        <el-table-column label="采购赠品数量" width="100" prop="purchaseGiftCounts" key="order2" />
                    </template>
                    <template v-else>
                        <el-table-column label="库存数量" width="80" prop="stock" key="goods" />
                    </template>
                    <el-table-column label="退货价" width="140" v-if="showInPrice()">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input
                                    v-model="scope.row.showPrices"
                                    :precision="showPricePrecision"
                                    @change="computeThenChangeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <template v-if="enabledBigUnit">
                        <el-table-column label="包装因子">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.packFactors'"
                                    :rules="rules.packFactors"
                                >
                                    {{ scope.row.packFactors }}
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="退货箱数" min-width="120">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bigUnitCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bigUnitCounts"
                                        @change="computeThenChangeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="散装数量" min-width="120">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bulkCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bulkCounts"
                                        @change="computeThenChangeCountAndMoney(scope.row)"
                                        :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column label="退货数量" width="120" :render-header="rendHeader1">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-model="scope.row.counts"
                                    :disabled="enabledBigUnit"
                                    @change="computeThenChangeCountAndMoney(scope.row)"
                                    :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="退货金额" width="90" :render-header="rendHeader2" v-if="showInPrice()">
                        <template slot-scope="scope"
                            ><span>
                                <span>{{ scope.row.purchaseRefundShowMoney }}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="退货赠品数量" width="120">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.giftCounts'"
                                :rules="rules.giftCounts"
                            >
                                <ef-stock-biz-count-input
                                    v-model="scope.row.giftCounts"
                                    @change="computeThenChangeCountAndMoney(scope.row)"
                                    :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="退货总数量" width="180">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.totalCounts'"
                                :rules="rules.totalCounts"
                            >
                                <ef-stock-biz-count-input
                                    v-model="scope.row.totalCounts"
                                    :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                                    :disabled="true"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
    </div>
</template>

<script>
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import { countGreaterThanZeroValidateRule, countValidateRule } from 'js/validate/ValidateStockBizRule';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import EfRemark from 'components/EfRemark';
import {
    deptCodeValidateRule,
    goodsCodeValidateRule,
    mobileValidateRule,
    packFactorsValidateRule,
    pricesValidateRule,
    remarkNotRequiredValidateRule,
} from 'js/validate/ValidateCommonRule';
import EfDeptTypeAndDeptSelect from '../../../components/EfDeptTypeAndDeptSelect';
import EfPriceInput from '../../../components/EfPriceInput';
import MoneyUtils from 'js/MoneyUtils';
import { DeptTypeEnum } from 'js/DeptCommon';
import StockBizCommon, { packFactor, PropertyConverter } from 'js/StockBizCommon';
import { get, postByFormDataAfterConfirmed } from 'request/http';

export default {
    name: 'PurchaseSelfOutCreate',
    components: { EfPriceInput, EfDeptTypeAndDeptSelect, PickUpGoods, EfStockBizCountInput, EfRemark },
    data() {
        return {
            //单方采购退货类型 0:按照单方采购入库单退，1:自行选择商品退
            purchaseSelfOutType: 0,
            enableOrderRefundFlag: false,
            repoDeptFlag: false,
            showPricePrecision: 2,

            search: '',
            enabledBigUnit: false,
            formExtend: {
                createTime: Util.nowFormatDate(),
                deptName: '',
                deptType: null,
                supplierName: '',
            },
            form: {
                purchaseSelfInCode: '',
                supplierCode: '',
                deptCode: '',
                bizCode: '',
                customer: '',
                customerMobile: '',
                customerAddress: '',
                remark: '',
                detailParams: [],
            },
            depts: [],
            selectedDept: null,
            //所选商品详情列表
            goodsDetailMap: new Map(),
            detailParamPropertyArrForSubmit: [
                'codes',
                'goodsCode',
                'counts',
                'packFactors',
                'giftCounts',
                new PropertyConverter('showPrices', 'prices', (price) => {
                    return MoneyUtils.moneyToDb(price);
                }),
            ],
            rules: {
                purchaseSelfInCode: {
                    required: true,
                    message: '请填写单采入库单号',
                    trigger: ['blur'],
                },
                deptCode: deptCodeValidateRule(),
                customer: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,10}$/,
                    message: '至少两个中文汉字或字母',
                    trigger: ['blur'],
                },
                customerMobile: mobileValidateRule(false),
                customerAddress: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa50-9]{2,30}$/,
                    message: '请正确输入客户地址',
                    trigger: ['blur'],
                },
                remark: remarkNotRequiredValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                packFactors: packFactorsValidateRule(),
                showPrices: null,
                counts: [
                    countValidateRule(
                        () => {
                            return this.form.detailParams || [];
                        },
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                giftCounts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                bigUnitCounts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                totalCounts: [
                    countGreaterThanZeroValidateRule(),
                    {
                        validator: (rule, count = 0, callback) => {
                            rule.fullField.match(/\.(\d*)\./);
                            const idx = Number(RegExp.$1);
                            let canRefundCount;
                            const detailParam = this.form.detailParams[idx];
                            if (this.enableOrderRefundFlag) {
                                canRefundCount = this.decimal(detailParam.purchaseCounts || 0)
                                    .add(detailParam.purchaseGiftCounts || 0)
                                    .toNumber();
                            } else {
                                canRefundCount = detailParam.stock || 0;
                            }
                            count <= canRefundCount ? callback() : callback(new Error('不可超过最大可退数量'));
                        },
                        trigger: ['change'],
                    },
                ],
            },
            baseUrl: '/purchase/selfOut',
            url: {
                querySelfIn: '/purchase/selfIn/info_c',
            },
        };
    },
    mounted() {
        this.changePurchaseSelfOutType(this.purchaseSelfOutType);
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            return Util.isEmpty(this.form.deptCode) || Util.isEmpty(this.search);
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.form.deptCode);
        },
        //禁用/启用按钮
        disableSaveButton() {
            return (
                Util.isEmpty(this.form.deptCode) ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
    },
    watch: {
        'formExtend.deptType': {
            handler(n, o) {
                this.repoDeptFlag = n === DeptTypeEnum.REPOSITORY;
                //单价精度
                this.showPricePrecision = this.repoDeptFlag ? 3 : 2;
                //更改价格验证规则
                this.rules.showPrices = pricesValidateRule(this.showPricePrecision);
            },
            immediate: true,
        },
    },
    methods: {
        changePurchaseSelfOutType(type) {
            const rules = this.rules;
            Object.assign(this.$data, this.$options.data());
            this.enableOrderRefundFlag = type === 0;
            this.purchaseSelfOutType = type;
            this.rules = rules;
            if (this.enableOrderRefundFlag) {
                this.$efApi.deptApi.managedAllDepts().then((rst) => {
                    this.depts = rst;
                });
            }
        },
        setDeptAndQueryHasBigUnit(deptCode, deptName, deptType) {
            this.form.deptCode = deptCode;
            this.formExtend.deptName = deptName;
            this.formExtend.deptType = deptType;
            this.form.detailParams = [];
            this.$efApi.deptPrivilegeApi.hasDeptPrivilege(deptCode, 'dept.biz.goods.allow.bigUnit').then((rst) => {
                this.enabledBigUnit = rst;
            });
        },
        setDeptType(deptType) {
            this.formExtend.deptType = deptType;
        },
        detailParamPropertyArrForAdd() {
            if (this.enableOrderRefundFlag) {
                this.$message.error('根据单方采购入库单退货方式无法手动添加商品');
                throw new Error();
            }
            const rst = [
                'goodsCode',
                'stock',
                new PropertyConverter(null, 'counts'),
                new PropertyConverter(null, 'giftCounts'),
                new PropertyConverter(null, 'showPrices'),
            ];
            if (this.enabledBigUnit) {
                rst.push(
                    new PropertyConverter(null, 'bigUnitCounts'),
                    new PropertyConverter(null, 'bulkCounts'),
                    new PropertyConverter(packFactor, 'packFactors', (packFactor) => {
                        return Util.isEmpty(packFactor) ? 1 : packFactor;
                    })
                );
            }
            return rst;
        },
        showPickUpGoods() {
            this.$refs.pickUpGoods.show(this.pickUpGoodsQueryParam(), this.formExtend.deptName);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ ...this.pickUpGoodsQueryParam(), search: this.search });
            this.setSelectedGoods(rst.data);
        },
        detailParamPropertyArrForOrderShow() {
            if (!this.enableOrderRefundFlag) {
                this.$message.error('自行选择商品退货方式无法查询单号');
                throw new Error();
            }
            const rst = [
                'goodsCode',
                'counts',
                'giftCounts',
                new PropertyConverter('prices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToThree(price);
                }),
                new PropertyConverter('counts', 'purchaseCounts'),
                new PropertyConverter('giftCounts', 'purchaseGiftCounts'),
            ];
            if (this.enabledBigUnit) {
                rst.push(
                    new PropertyConverter('packFactors', 'packFactors', (packFactors, detailParam) => {
                        //获取单据中商品的包装因子
                        return Util.isEmpty(packFactors) ? 1 : packFactors;
                    }),
                    new PropertyConverter(null, 'bigUnitCounts', (bigUnitCounts, detailParam) => {
                        return Math.floor(detailParam.counts / detailParam.packFactors);
                    }),
                    new PropertyConverter(null, 'bulkCounts', (bulkCounts, detailParam) => {
                        return this.decimal(detailParam.counts)
                            .sub(this.decimal(detailParam.bigUnitCounts).mul(detailParam.packFactors))
                            .toNumber();
                    })
                );
            }
            rst.push(
                new PropertyConverter(null, 'purchaseRefundShowMoney', (purchaseRefundShowMoney, detailParam) => {
                    return this.computeShowMoney(detailParam);
                }),
                new PropertyConverter(null, 'totalCounts', (totalCounts, detailParam) => {
                    return this.decimal(detailParam.counts).add(detailParam.giftCounts).toNumber();
                })
            );
            return rst;
        },
        computeThenChangeCountAndMoney(row) {
            this.$nextTick(() => {
                //计算数量和金额
                let counts;
                if (this.enabledBigUnit) {
                    //启用大单位
                    let bulkCounts = row.bulkCounts || 0;
                    const packFactors = row.packFactors || 1;
                    let bigUnitCounts = row.bigUnitCounts || 0;
                    const bigUnitCountsForBulkCounts = Math.floor(bulkCounts / packFactors);
                    if (bigUnitCountsForBulkCounts > 0) {
                        bigUnitCounts = this.decimal(bigUnitCounts).add(bigUnitCountsForBulkCounts).toNumber();
                        bulkCounts = this.decimal(bulkCounts)
                            .sub(this.decimal(bigUnitCountsForBulkCounts).mul(packFactors))
                            .toNumber();
                        row.bulkCounts = bulkCounts;
                        row.bigUnitCounts = bigUnitCounts;
                    }
                    counts = this.computeCount(row);
                } else {
                    counts = row.counts || 0;
                }
                row.counts = counts;
                row.purchaseRefundShowMoney = this.computeShowMoney(row);
                //计算总数量
                row.totalCounts = this.decimal(counts)
                    .add(row.giftCounts || 0)
                    .toNumber();
            });
        },
        computeCount(detailParam) {
            let counts;
            if (this.enabledBigUnit) {
                //启用大单位
                counts = this.decimal(detailParam.packFactors || 1)
                    .mul(detailParam.bigUnitCounts || 0)
                    .add(detailParam.bulkCounts || 0)
                    .toNumber();
            } else {
                //未启用大单位
                counts = detailParam.counts || 0;
            }
            return counts;
        },
        computeShowMoney(detailParam) {
            const purchaseRefundShowMoney = MoneyUtils.moneyToDb(
                this.decimal(detailParam.counts || 0)
                    .mul(detailParam.showPrices || 0)
                    .toNumber()
            );
            return this.repoDeptFlag
                ? MoneyUtils.moneyToThree(purchaseRefundShowMoney)
                : MoneyUtils.moneyToYuan(purchaseRefundShowMoney);
        },
        async validateAndSelectPurchaseSelfInOrder() {
            //置空，清除数据
            this.form.deptCode = null;
            this.form.bizCode = null;
            this.form.detailParams = [];
            if (!Util.isEmpty(this.selectedDept)) {
                this.selectedDept.selected = false;
            }
            this.selectedDept = null;

            if (Util.isEmpty(this.form.purchaseSelfInCode)) {
                this.$message.error('请输入单方采购入库订单号');
                return;
            }
            const purchaseSelfInOrder = (await get(`${this.url.querySelfIn}/${this.form.purchaseSelfInCode}`)) || [];
            if (
                Util.isEmpty(purchaseSelfInOrder) ||
                purchaseSelfInOrder.stockStatus !== StockBizCommon.StockBizStatusEnum.CHANGED
            ) {
                this.$message.error('请检查单方采购入库订单是否存在或已入库');
                return;
            }
            const matchedDept = this.depts.find((e) => {
                return !Util.isEmpty(purchaseSelfInOrder.deptCode) && e.code === purchaseSelfInOrder.deptCode;
            });
            if (Util.isEmpty(matchedDept)) {
                this.$message.error('单方采购入库订单无法匹配上当前退货机构列表');
                return;
            }
            //将退货机构列表中匹配上的机构设置为可选且自动选中
            matchedDept.selected = true;
            this.selectedDept = matchedDept;
            this.form.deptCode = matchedDept.code;
            this.form.bizCode = this.form.purchaseSelfInCode;
            this.formExtend.deptType = matchedDept.type;
            this.formExtend.supplierName = purchaseSelfInOrder.supplierName;
            this.form.supplierCode = purchaseSelfInOrder.supplierCode;
            this.enabledBigUnit = await this.$efApi.deptPrivilegeApi.hasDeptPrivilege(
                this.form.deptCode,
                'dept.biz.goods.allow.bigUnit'
            );
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                purchaseSelfInOrder,
                this.detailParamPropertyArrForOrderShow()
            );
            this.goodsDetailMap = arr.goodsDetails;
            this.form.detailParams = arr.detailParams;
        },
        handleDelete(index) {
            this.form.detailParams.splice(index, 1);
        },
        setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.form.detailParams || []);
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd()
            );
            if (oldDetails.length <= 150) {
                this.form.detailParams = oldDetails;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        pickUpGoodsQueryParam() {
            return {
                deptCode: this.form.deptCode,
                goodsStatusIn: [0, 1],
                skuSinglePurchaseFlag: 1,
                page: 1,
                limit: 100,
            };
        },
        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.form.detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                //将商品详情列表转换成多字段数组形式
                const createParam = {
                    ...this.form,
                    ...StockBizCommon.convertStockBizDetailParamsToParamArrObj(
                        this.form.detailParams,
                        this.detailParamPropertyArrForSubmit
                    ),
                };
                //删除非提交必要字段数据
                delete createParam.detailParams;
                await postByFormDataAfterConfirmed(`${this.baseUrl}/createExtend`, createParam);
                this.goBackAndReload();
            });
        },

        rendHeader1(h, { column }) {
            const _this = this;
            return h(
                'div',
                {
                    style: 'display:flex;',
                },
                [
                    h('span', column.label),
                    _this.enabledBigUnit
                        ? h('prompt-message', {
                              props: { messages: ['退货总数 = 退货箱数 * 包装因子 + 散装数量'] },
                          })
                        : null,
                ]
            );
        },
        rendHeader2(h, { column }) {
            const _this = this;
            return h(
                'div',
                {
                    style: 'display:flex;',
                },
                [
                    h('span', column.label),
                    _this.enabledBigUnit
                        ? h('prompt-message', {
                              props: { messages: ['退货金额 = 退货价 * 退货总数'] },
                          })
                        : null,
                ]
            );
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
